<template>
	<div class="detail">
		<div>
			<a-form style="margin-top: 30px;" ref="form" :model="modalRef" scrollToFirstError name="form"
				:labelCol="{span: 6, xxl: 5}" :wrapperCol="{span: 14, xxl: 13 }" @finish="onSubmit">
				<a-form-item label="影票赠送积分" name="filmTicketPointStatus" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-switch v-model:checked="modalRef.filmTicketPointStatus" />
				</a-form-item>

				<a-form-item label="影票送积分额度" name="filmTicketPoints" :rules="[{required: true, message: '必填项不允许为空'}]"
					extra="默认满20元后，以元为单位赠送订单金额数的积分">
					<a-input-number v-model:value="modalRef.filmTicketPoints" :min="0" :precision="0"
						placeholder="请输入"></a-input-number>
				</a-form-item>

				<a-form-item label="卖品赠送积分" name="snackGoodsPointStatus" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-switch v-model:checked="modalRef.snackGoodsPointStatus" />
				</a-form-item>
				<a-form-item label="卖品送积分额度" name="snackGoodsPoints" :rules="[{required: true, message: '必填项不允许为空'}]"
					extra="默认满0元后，以元为单位赠送订单金额数的积分">
					<a-input-number v-model:value="modalRef.snackGoodsPoints" :min="0" :precision="0"
						placeholder="请输入"></a-input-number>
				</a-form-item>

				<a-form-item label="演出赠送积分" name="performPointStatus" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-switch v-model:checked="modalRef.performPointStatus" />
				</a-form-item>
				<a-form-item label="演出送积分额度" name="performPoints" :rules="[{required: true, message: '必填项不允许为空'}]"
					extra="默认满50元后，以元为单位赠送订单金额数的积分">
					<a-input-number v-model:value="modalRef.performPoints" :min="0" :precision="0"
						placeholder="请输入"></a-input-number>
				</a-form-item>

				<a-form-item label="商品赠送积分" name="shopGoodsPointStatus" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-switch v-model:checked="modalRef.shopGoodsPointStatus" />
				</a-form-item>
				<a-form-item label="商品送积分额度" name="shopGoodsPoints" :rules="[{required: true, message: '必填项不允许为空'}]"
					extra="默认满0元后，以元为单位赠送订单金额数的积分">
					<a-input-number v-model:value="modalRef.shopGoodsPoints" :min="0" :precision="0"
						placeholder="请输入"></a-input-number>
				</a-form-item>

				<a-form-item label="签到获得积分" name="signPoints" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input-number v-model:value="modalRef.signPoints" :min="0" :precision="0"
						placeholder="请输入"></a-input-number>
				</a-form-item>

				<a-form-item label="每日赠送积分上限" name="dayLimit" :rules="[{required: true, message: '必填项不允许为空'}]" extra="为0则无上限">
					<a-input-number v-model:value="modalRef.dayLimit" :min="0" :precision="0"
						placeholder="请输入"></a-input-number>
				</a-form-item>

				<a-row>
					<a-col :span="24" style="margin-top: 20px;text-align: center;">
						<a-button type="primary" html-type="submit">提交</a-button>
					</a-col>
				</a-row>
			</a-form>
		</div>
	</div>
</template>

<script>
	import { getPointsSettingDetail, updatePointsSetting } from '@/service/modules/operation.js';
	export default {
		components: {},
		data() {
			return {
				loading: false,
				modalRef: {},
			}
		},
		created() {
			this.getData();
		},
		methods: {
			async getData() {
				this.loading = true;
				let ret = await getPointsSettingDetail({});
				this.loading = false;
				if (ret.code === 200) {
					this.modalRef = ret.data;
				}
			},
			async onSubmit() {
				let postData = JSON.parse(JSON.stringify(this.modalRef));
				this.loading = true;
				let ret = await updatePointsSetting(postData);
				this.loading = false;
				if (ret.code === 200) {
					this.$message.success('修改成功！');
					this.getData();
				}
			}
		}
	};
</script>

<style lang="less" scoped>

</style>