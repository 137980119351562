import { send } from "../index";

export function getCinemaAnnouncementCenterList(data, opts = {}) {
    //  公告列表
    return send({
        url: "/admin/cinemaAnnouncementCenter/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getCinemaAnnouncementCenterDetail(data, opts = {}) {
    //  公告详情
    return send({
        url: "/admin/cinemaAnnouncementCenter/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function saveCinemaAnnouncementCenter(data, opts = {}) {
    //  新增公告
    return send({
        url: "/admin/cinemaAnnouncementCenter/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updateCinemaAnnouncementCenter(data, opts = {}) {
    //  编辑公告
    return send({
        url: "/admin/cinemaAnnouncementCenter/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function deleteCinemaAnnouncementCenter(data, opts = {}) {
    //  删除公告
    return send({
        url: "/admin/cinemaAnnouncementCenter/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPlatformAdImageList(data, opts = {}) {
    //  广告图列表
    return send({
        url: "/admin/platformAdImage/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function savePlatformAdImage(data, opts = {}) {
    //  新增广告图
    return send({
        url: "/admin/platformAdImage/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updatePlatformAdImage(data, opts = {}) {
    //  编辑广告图
    return send({
        url: "/admin/platformAdImage/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function deletePlatformAdImage(data, opts = {}) {
    //  广告图删除
    return send({
        url: "/admin/platformAdImage/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPlatformAdImageDetail(data, opts = {}) {
    //  广告图详情
    return send({
        url: "/admin/platformAdImage/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPlatformActivityList(data, opts = {}) {
    //  活动列表
    return send({
        url: "/admin/platformActivity/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function savePlatformActivity(data, opts = {}) {
    //  新增活动
    return send({
        url: "/admin/platformActivity/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updatePlatformActivity(data, opts = {}) {
    //  编辑活动
    return send({
        url: "/admin/platformActivity/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function deletePlatformActivity(data, opts = {}) {
    //  活动删除
    return send({
        url: "/admin/platformActivity/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPlatformActivityDetail(data, opts = {}) {
    //  活动详情
    return send({
        url: "/admin/platformActivity/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getAgreementSettingList(data, opts = {}) {
    //  协议列表
    return send({
        url: "/admin/agreementSetting/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getAgreementSettingDetail(data, opts = {}) {
    //  协议详情
    return send({
        url: "/admin/agreementSetting/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updateAgreementSetting(data, opts = {}) {
    //  协议编辑
    return send({
        url: "/admin/agreementSetting/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPointsSettingDetail(data, opts = {}) {
    //  积分设置详情
    return send({
        url: "/admin/pointsSetting/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updatePointsSetting(data, opts = {}) {
    //  积分设置
    return send({
        url: "/admin/pointsSetting/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getRefundReasonList(data, opts = {}) {
    //  售后理由设置
    return send({
        url: "/admin/refundReason/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function saveRefundReason(data, opts = {}) {
    //  售后理由设置
    return send({
        url: "/admin/refundReason/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updateRefundReason(data, opts = {}) {
    //  售后理由设置
    return send({
        url: "/admin/refundReason/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getRefundReasonDetail(data, opts = {}) {
    //  售后理由设置
    return send({
        url: "/admin/refundReason/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function deleteRefundReason(data, opts = {}) {
    //  售后理由设置
    return send({
        url: "/admin/refundReason/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getQuestionModuleList(data, opts = {}) {
    //  问题模块列表
    return send({
        url: "/admin/questionModule/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getQuestionModuleDetail(data, opts = {}) {
    //  问题模块详情
    return send({
        url: "/admin/questionModule/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function saveQuestionModule(data, opts = {}) {
    //  问题模块保存
    return send({
        url: "/admin/questionModule/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updateQuestionModule(data, opts = {}) {
    //  问题模块修改
    return send({
        url: "/admin/questionModule/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function deleteQuestionModule(data, opts = {}) {
    //  问题模块删除
    return send({
        url: "/admin/questionModule/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function disabledQuestionModule(data, opts = {}) {
    //  问题模块启用/禁用
    return send({
        url: "/admin/questionModule/switch.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getQuestionModuleAllList(data, opts = {}) {
    //  问题模块列表无分页
    return send({
        url: "/admin/questionModule/listNoPage.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getQuestionList(data, opts = {}) {
    //  问题列表
    return send({
        url: "/admin/questionInfo/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getQuestionDetail(data, opts = {}) {
    //  问题列表详情
    return send({
        url: "/admin/questionInfo/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function saveQuestion(data, opts = {}) {
    //  问题列表新增
    return send({
        url: "/admin/questionInfo/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updateQuestion(data, opts = {}) {
    //  问题列表编辑
    return send({
        url: "/admin/questionInfo/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function deleteQuestion(data, opts = {}) {
    //  问题列表删除
    return send({
        url: "/admin/questionInfo/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function disabledQuestion(data, opts = {}) {
    //  问题列表启用/禁用
    return send({
        url: "/admin/questionInfo/switch.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function setHotQuestion(data, opts = {}) {
    //  问题列表设置猜你想问
    return send({
        url: "/admin/questionInfo/setHot.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getActivityList(data, opts = {}) {
    //  抽奖活动
    return send({
        url: "/admin/pointLotteryActivity/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function saveActivity(data, opts = {}) {
    //  抽奖活动保存
    return send({
        url: "/admin/pointLotteryActivity/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updateActivity(data, opts = {}) {
    //  抽奖活动修改
    return send({
        url: "/admin/pointLotteryActivity/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getActivityDetail(data, opts = {}) {
    //  抽奖活动详情
    return send({
        url: "/admin/pointLotteryActivity/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function deleteActivity(data, opts = {}) {
    //  抽奖活动删除
    return send({
        url: "/admin/pointLotteryActivity/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function disabledActivity(data, opts = {}) {
    //  抽奖活动启用、禁用
    return send({
        url: "/admin/pointLotteryActivity/switch.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getActivityGiftList(data, opts = {}) {
    //  奖品列表
    return send({
        url: "/admin/pointLotteryGift/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getActivityGiftDetail(data, opts = {}) {
    //  奖品列表
    return send({
        url: "/admin/pointLotteryGift/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updateActivityGift(data, opts = {}) {
    //  奖品列表
    return send({
        url: "/admin/pointLotteryGift/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getActivityRecord(data, opts = {}) {
    //  参与记录
    return send({
        url: "/admin/pointLotteryRecord/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function supplementGift(data, opts = {}) {
    //  补发奖品
    return send({
        url: "/admin/pointLotteryRecord/supplementGift.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getMessageList(data, opts = {}) {
    //  消息列表
    return send({
        url: "/admin/platMessage/list.do",
        method: "POST",
        data,
        ...opts,
    });
}
